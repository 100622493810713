import React from "react";
import {Stack, Text} from "@chakra-ui/core";
import Button from "@pency/components/controls/Button";
import {useRouter} from "next/dist/client/router";

import LoginForm, {FormData} from "~/tenant/forms/SlugForm";

const IndexScreen: React.FC = () => {
  const [status, setStatus] = React.useState<"init" | "pending">("init");
  const {push} = useRouter();

  function handleSubmit({slug}: FormData) {
    setStatus("pending");

    push(`/${slug}`);
  }

  return (
    <Stack
      backgroundColor="primary.900"
      backgroundImage="url(./assets/bg.svg)"
      backgroundRepeat="no-repeat"
      backgroundSize={{base: "cover", lg: "60%"}}
      flex={1}
      height="100%"
      minHeight="100vh"
      padding={{base: 6, lg: 20}}
    >
      <Stack
        shouldWrapChildren
        backgroundColor="white"
        borderRadius="lg"
        boxShadow="lg"
        marginX="auto"
        maxWidth={480}
        padding={{base: 6, lg: 10}}
        spacing={6}
        width="100%"
      >
        <Text fontSize="2xl" fontWeight={500}>
          Ingresa a tu cuenta
        </Text>
        <LoginForm onSubmit={handleSubmit}>
          {({form, submit}) => (
            <Stack shouldWrapChildren spacing={6}>
              {form}
              <Button
                isLoading={status === "pending"}
                size="lg"
                variantColor="primary"
                width="100%"
                onClick={submit}
              >
                Inicia sesión
              </Button>
            </Stack>
          )}
        </LoginForm>
      </Stack>
    </Stack>
  );
};

export default IndexScreen;
