import React from "react";
import {useForm, Controller} from "react-hook-form";
import FormControl from "@pency/components/form/FormControl";
import {yupResolver} from "@hookform/resolvers";
import * as yup from "yup";
import {Stack} from "@chakra-ui/core";

import SlugInput from "../../inputs/SlugInput";

export interface FormData {
  slug: string;
}

interface Props {
  defaultValues?: Partial<FormData>;
  onSubmit: (values: Partial<FormData>) => void;
  children: (options: {
    form: JSX.Element;
    isDirty: boolean;
    submit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  }) => JSX.Element;
}

// Form schema
const schema = yup.object<FormData>({
  slug: yup
    .string()
    .matches(
      /^[a-z0-9\.\_]*$/,
      "Solo puede contener letras minúsculas, números, puntos y guión bajo.",
    )
    .min(4, "El nombre de la cuenta debe tener al menos 4 caracteres")
    .required("Este campo es requerido"),
});

const CreateForm: React.FC<Props> = ({defaultValues, children, onSubmit}) => {
  const {handleSubmit: submit, errors, control, formState} = useForm<Partial<FormData>>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  function handleSubmit(formData: FormData) {
    return onSubmit(formData);
  }

  return children({
    submit: submit(handleSubmit),
    isDirty: formState.isDirty,
    form: (
      <form onSubmit={submit(handleSubmit)}>
        <Stack spacing={6}>
          <FormControl error={errors.slug?.message} isInvalid={Boolean(errors.slug)} name="slug">
            <Controller
              as={SlugInput}
              control={control}
              defaultValue=""
              name="slug"
              placeholder="micomercio"
              size="lg"
            />
          </FormControl>
        </Stack>
      </form>
    ),
  });
};

export default CreateForm;
